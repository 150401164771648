import React from 'react'
import { Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import InlineSVG from '../components/inlineSVG'
import IconArrowLeft from '../images/icon-arrow-left.svg'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const BackButton = (props) => {
  
  if(!props) return null;

  return (
    <a href={props.url} className="kb__button--back" title={props.text}>
      <InlineSVG url={IconArrowLeft} classes="kb__back-button-arrow" />
      {props.text}
    </a>
  )
}

export default BackButton
