import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import {RichText, Link} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import BackButton from '../components/backButton'

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton
} from "react-share";


import '../components/career.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

export const query = graphql`
query careerQuery($uid: String) {
  prismic {
    allCareers(uid: $uid) {
      edges {
        node {
          _linkType
          _meta {
            uid
            type
            tags
          }
          apply_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              target
              _linkType
              url
            }
          }
          location
          meta_description
          meta_image
          meta_title
          posting
          title
        }
      }
    }
  }
}
`


const Career = props => {

  if(!props) return null;
  
  const doc = props.data.prismic.allCareers.edges[0];

  if(!doc) return null;
  if(!doc.node) return null;

  const career = doc.node;

  var currentUrl = 'https://kickbooster.me/careers/'+career._meta.uid;

  return (
    <Layout>

      <SEO 
        title={RichText.asText(career.meta_title)}
        meta={[
          {
            property: `og:image`,
            content: career.meta_image.facebook.url
          },{
            property: `twitter:image`,
            content: career.meta_image.twitter.url
          }
        ]}
      />
      
      <section className="kb__career">
        <div className="kb__container--full">

          <BackButton text="Back to careers" url="/careers" />

          <div className="kb__grid-center">
            <div className="kb__col-6_md-7_sm-12">
              <h1 className="sub-headline"> {RichText.asText(career.title)}</h1>
              {RichText.render(career.posting)}

              {career.apply_link && 
                <a className="kb__button--primary kb__button--large" href={RichLink(career.apply_link)} title="Apply for this position now">Apply now</a>
              }
            </div>

            <aside className="kb__col-3_md-5_sm-12 kb__career-sidebar">
              <div className="kb__career-sidebar-wrapper">
                <div className="kb__career-card">
                  <h4>If you have what it takes to be Bold, submit your cover letter, resume and portfilio now!</h4>
                  
                  {career.apply_link && 
                    <a className="kb__button--primary kb__button--large" href={RichLink(career.apply_link)} title="Apply for this position now">Apply now</a>
                  }

                  <p className="kb__body-sm grey">If you meet the criteria, we’ll be in touch to set up an interview to see your skills in person.</p>

                </div>

                <div className="kb__career-card kb--no-bs">
                  <h5>Know someone who would be a good fit for this role?</h5>

                  <p className="kb__body-sm grey">If you aren’t the right person for this role but you know someone who might be, please share this page with them.</p>                  

                  <div className="kb__career-sharing">
                    <FacebookShareButton url={currentUrl}><FacebookIcon /></FacebookShareButton>
                    <TwitterShareButton url={currentUrl}><TwitterIcon /></TwitterShareButton>
                    <LinkedinShareButton url={currentUrl}><LinkedinIcon /></LinkedinShareButton>
                    <EmailShareButton url={currentUrl}><EmailIcon /></EmailShareButton>
                  </div>

                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default Career;

